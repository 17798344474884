@import '/styles/vars';

.layer {
    width: 100vw;
    width: var(--vw100);
    left: 50%;
    overflow: hidden;
    position: relative;
    margin-left: -50vw;
}

.wrap {
    margin: 0 auto;
    max-width: $wrap;
    padding: 0 36px;
    @include mobile {
        padding: 0 12px;
    }
}
