@import '/styles/vars';

html {
    .stories {
        padding: 66px 0 54px 0;
        margin-top: -66px;
        @media (max-width: 1370px) {
            padding: 7vw 0 1vw 0;
            margin-top: -7vw;
        }
        @include mobile {
            display: none;
        }
        @media (max-width: 480px) {
            display: block;
        }
    }

    .storiesWrap {
        position: relative;
        margin: 4.484vw 0 5.415vw 0;
    }

    .bg {
        position: absolute;
        width: 100vw;
        width: var(--vw100);
        top: 0;
        left: 50%;
        margin-left: -50vw;
        height: 100%;
        background: $color_light;
    }

    .top {
        width: 100%;
        position: absolute;
        bottom: 100%;
        height: 4.484vw;
        margin-top: -6px;
        fill: $color_light;
    }

    .bottom {
        fill: $color_light;
        width: 100%;
        position: absolute;
        top: 100%;
        height: 5.415vw;
        margin-top: -6px;
    }

    .red {
        width: 17%;
        transform: translate(0, 40%);
        position: absolute;
        bottom: 0;
        left: -45px;
        @include mobile {
            width: 29%;
            transform: translate(0, 24%) scaleX(-1);
            max-width: 140px;
            right: -15px;
            left: auto;
        }
    }

    .green {
        width: 19%;
        position: absolute;
        right: -30px;
        margin-top: -4.484vw;
        transform: translate(0, -30%);
        @include mobile {
            width: 43%;
            max-width: 190px;
            right: -10px;
        }
    }

    .content {
        position: relative;
        display: flex;
        align-items: center;
        @include mobile {
            display: block;
        }
    }

    .heading {
        width: 23%;
        padding-top: 18px;
        padding-bottom: 180px;
        line-height: 1.5;
        @media (max-width: 1370px) {
            font-size: 2.3vw;
        }
        @include mobile {
            font-size: 18px;
            width: 240px;
            padding: 12px 40px 24px 0;
        }
    }

    // .more {
    //     @include mobile {
    //         margin: 0 0 0 auto;
    //         display: block;
    //         width: 150px;
    //     }
    // }

    .info h3 {
        @media (max-width: 1370px) {
            font-size: 2vw;
        }
        @include mobile {
            font-size: 18px;
        }
    }

    .image {
        border-radius: $radiusImg;
        width: 30%;
        position: absolute;
        left: 34%;
        top: 24px;
        overflow: hidden;
        .img {
            padding-bottom: 100%; // 135%;
        }
        video {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 300%;
            height: 100%;
        }
        a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        @include mobile {
            position: relative;
            width: auto;
            left: 0;
            top: auto;
            margin: 0 auto 24px auto;
        }
    }

    .arrow {
        width: 7%;
        height: 26px;
        top: 72px;
        left: 24.5%;
        position: absolute;
        transform: rotate(15deg);
        @include mobile {
            left: 200px;
            width: 100px;
            top: 60px;
            transform: rotate(23deg);
        }
    }

    .nav {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 55%;
        svg {
            width: 36px;
            height: 36px;
            stroke-width: 1.2;
        }
        @include mobile {
            display: none;
        }
    }

    .prev {
        left: 26%;
    }

    .next {
        right: 0;
    }

    .details {
        position: absolute;
        left: 69%;
        width: 28%;
        // top: 30px;
        p {
            max-width: 400px;
            margin: 18px 0 30px 0;
        }
        @media (max-width: 1370px) {
            font-size: $font14;
        }
        @include mobile {
            position: relative;
            left: 0;
            width: auto;
            display: flex;
            align-items: flex-start;
            padding-bottom: 60px;
            p {
                margin-bottom: 18px;
            }
        }
        @media (max-width: 520px) {
            padding-bottom: 8vw;
        }
    }

    .logo {
        width: 40%;
        position: relative;
        .in {
            padding-bottom: 100%;
        }
        .holder {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            > div {
                width: 100%;
            }
        }
        a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        img {
            mix-blend-mode: multiply;
            object-position: left center;
        }
        @include mobile {
            width: 80px;
            width: 25%;
            flex-shrink: 0;
            margin: 0 18px;
            .holder {
                align-items: flex-start;
            }
            img {
                object-position: center top;
            }
        }
    }
}
